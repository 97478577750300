.dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20px;
    border: 2px dashed #6c757d;
    margin-bottom: 20px;
    cursor: pointer;
    background-color: transparent;
  }
  