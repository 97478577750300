/* Ensure the root element covers the entire viewport */
html, body {
  margin: 0;
  padding: 0;
  height: 100%; /* Ensures full height */
  min-height: 100vh;
}


#root {
  /* Ensure the root element takes the full height of its parent */
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%; /* This makes sure that the App component takes up the entire viewport height */
}

.content-row {
  display: flex;
  position: relative; /* For absolute positioning of ThreeView */
  padding-top: 0; /* Offset from the top */
  height: 100vh;
}


.resize-handle {
  width: 10px;
  height: 100%;
  cursor: ew-resize;
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
}

.resize-handle::before,
.resize-handle::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
  height: 20px;
  width: 1px;
  background-color: darkgrey; /* Line color */
}

.resize-handle::after {
  left: 6px; /* Adjust so it's spaced apart from the ::before line */
}

.sidebar {
  position: relative;
  overflow-y: auto; /* Allows vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */
  margin-left: 10px; /* Offset from the side */
  box-sizing: border-box; /* Ensures padding doesn't add to the width */
  border: 1px solid rgba(0, 0, 0, 0.12); /* Material UI Paper outline */
}

.main-content {
  flex-grow: 1;
  padding: 0px;
}

.three-view-overlay {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 10; /* Above other content */
}